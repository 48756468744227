const config = {
  // eslint-disable-next-line max-len
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: '',
  defaultPath: '/rotations/',
  fontFamily: '\'Roboto\', sans-serif',
  borderRadius: 12,
  outlinedFilled: true,
  theme: 'light',
  presetColor: 'theme4', // default, theme1, theme2, theme3, theme4, theme5, theme6
  // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  i18n: 'en',
  rtlLayout: false,
  jwt: {
    secret: 'SECRET-KEY',
    timeout: '1 days',
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
  auth0: {
    client_id: 'HvYn25WaEHb7v5PBT7cTYe98XATStX3r',
    domain: 'demo-localhost.us.auth0.com',
  },
};

export default config;
